<template>
  <div>
    <div
      v-if="loading"
      class="text-center d-flex justify-content-center align-items-center loader"
    >
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <b-card v-else>
      <b-form class="p-1">
        <b-row>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Category"
              label-for="Job Title"
            >
              <v-select
                :disabled="mode === 'show'"
                v-model="SelectedCategory"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="categories"
                item-value="id"
                id="id"
                placeholder="Select Category"
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Working Hour/Day"
              label-for="hour"
            >
              <b-form-input
                :readonly="mode === 'show'"
                id="hour"
                v-model="form.hours"
                placeholder="8 hr/d - 5 days"
                required
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-for="(skill, index) in form.skill"
          :key="index"
          class="d-flex flex-row align-items-center"
        >
          <b-col cols="12" md="6">
            <b-form-group id="input-group-2" label="Skill" label-for="Skill">
              <v-select
                v-model="skill.skill_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="skills"
                label="skill"
                :reduce="(skill) => skill.id"
                placeholder="Select Skill"
                class="search-input bg-white"
                :disabled="mode === 'show'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="5">
            <b-form-group
              id="input-group-2"
              label="Skill Level"
              label-for="Skill Level"
            >
              <v-select
                v-model="skill.skill_level"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="options"
                :reduce="(option) => option.title"
                label="title"
                placeholder="Select Skill Level"
                class="search-input bg-white"
                :disabled="mode === 'show'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="1" class="text-center p-0 m-0">
            <button
              @click.prevent="remove"
              class="btn btn-sm btn-danger"
              v-if="form.skill.length > 1 && mode !== 'show'"
            >
              X
            </button>
            <button
              v-if="
                skills.length > form.skill.length &&
                index === form.skill.length - 1 &&
                mode !== 'show'
              "
              @click.prevent="addMore"
              class="btn-sm btn btn-success shadow-lg"
              style="margin-left: 5px"
            >
              +
            </button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Description"
              label-for="Description"
            >
              <quill-editor
                :disabled="mode === 'show'"
                v-model="form.description"
                class="bg-light data-text"
                :options="editorOption"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Requirements"
              label-for="requirements"
            >
              <quill-editor
                :disabled="mode === 'show'"
                v-model="form.requirements"
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Responsibilities"
              label-for="Responsibilities"
            >
              <quill-editor
                :disabled="mode === 'show'"
                v-model="form.responsibility"
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="input-group-2"
              label="Benefits"
              label-for="benefits"
            >
              <quill-editor
                :disabled="mode === 'show'"
                v-model="form.benefits"
                class="bg-light data-text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="d-flex flex-row justify-content-between">
            <router-link
              to="/job-post/view-job-templete"
              class="mx-1 px-3 decline btn"
            >
              Back
            </router-link>
            <b-row>
              <!-- <b-col>
                <b-button class="preview rounded-circle p-1">
                  <img src="@/assets/images/icons/eye.png" alt="" />
                </b-button>
              </b-col> -->
              <b-col>
                <b-button
                  v-if="!$route.params.id"
                  @click.prevent="handleSubmit"
                  class="mx-1 px-4 send-btn"
                >
                  Save
                </b-button>
                <b-button
                  v-if="$route.params.id"
                  @click.prevent="handleUpdate"
                  class="mx-1 px-4 send-btn"
                >
                  Update
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { toast } from "@/utils/toast";
import { isEmpty } from "@/utils/validate";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    vSelect,
    quillEditor,
    BSpinner,
  },
  props: {
    mode: {
      type: String,
      default: "show",
    },
  },

  data() {
    return {
      SelectedCategory: null,
      SelectedSkill: null,
      loading: false,
      categories: [],
      fields: [],
      options: [{ title: "Beginner" }, { title: "Intermediate" }, { title: "Expert" }],
      skills: [],
      form: {
        category_id: "",
        responsibility: "",
        benefits: "",
        requirements: "",
        description: "",
        short_description: "null",
        skill: [
          {
            skill_id: "",
            skill_level: "",
          },
        ],
        
      },
      editorOption: {
          placeholder: "Enter position description here...",
        },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getJobTemplete();
    }

    this.getFields();
    this.getCategories();
  },

  mounted() {
    this.getSkills();
  },

  methods: {
    getSkills() {
      let query = {
        limit: 1000,
      };
      this.$store.dispatch("skill/getSkills", query).then((response) => {
        this.skills = response.data;
      });
    },
    addMore() {
      this.form.skill.push({
        skill_id: "",
        skill_level: "",
      });
    },
    remove(index) {
      if (this.form.skill.length > 1) {
        this.form.skill.splice(index, 1);
      }
    },
    handleSubmit() {
      if (this.SelectedCategory) {
        this.form.category_id = this.SelectedCategory.id;
      }

      if (isEmpty(this.form)) {
        toast(
          "Validation Error",
          "AlertTriangleIcon",
          "danger",
          "Please fill all the form"
        );
      } else {
        this.form.title = "demo";
        this.loading = true;
        this.$store
          .dispatch("job/storeJobTemplete", this.form)
          .then((response) => {
            if (response.status_code === 200) {
              toast(
                "Success",
                "CheckIcon",
                "success",
                "Job template successfully"
              );
              // this.$router.push({ name: "job-post-draft" });
              this.loading = false;
            }
          })
          .catch((error) => {
            toast("Error", "AlertTriangleIcon", "danger", "Error Creating Job");
            this.loading = false;
          });
        this.loading = false;
      }
    },
    handleUpdate() {
      if (this.SelectedCategory) {
        this.form.category_id = this.SelectedCategory.id;
      }
      this.loading = true;
      this.$store
        .dispatch("job/storeJobTemplete", this.form)
        .then((response) => {
          if (response.status_code === 200) {
            toast(
              "Success",
              "CheckIcon",
              "success",
              "Job Templete Update Successfully"
            );
            this.loading = false;
            this.$router.push({ name: "view-job-templete" });
          }
        })
        .catch((error) => {
          this.loading = false;
          toast("Error", "AlertTriangleIcon", "danger", "Error Updating Job");
        });
      this.loading = false;
    },
    getFields() {
      let query = {
        limit: 1000,
      };
      this.$store.dispatch("skill/getSkills", query).then((response) => {
        this.fields = response.data;
      });
    },
    getCategories() {
      this.$store
        .dispatch("category/getCategories", this.query)
        .then((response) => {
          this.categories = response.data.data;
        });
    },

    getJobTemplete() {
      this.$store
        .dispatch("job/getJobTemplete", this.$route.params.id)
        .then((response) => {
          this.form = response.data[0];
          this.SelectedCategory = response.data[0]?.category;

          if (response.data[0]?.skill == null) {
            this.form.skill = [
              {
                skill_id: "",
                skill_level: "",
              },
            ];
          }
        })
        .catch((error) => {
          toast("Error", "AlertTriangleIcon", "danger", "Error Creating Job");
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.btn-space {
  margin: 2px;
}

.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e;
}

.decline {
  background-color: #ff4f5d1a !important;
  color: #ff4f5d !important;
  border-color: transparent !important;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
}

.send-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);

  border-color: transparent !important;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}

.preview {
  border-color: transparent !important;
  background: linear-gradient(315deg, white 100%, white 100%, white 100%);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
